<template>
  <b-card>
    <header-table title="المعلومات العامة" @create="onAdd" />
    <data-table ref="estatesTableee" :fields="fields" ep="/general-information">
      <template #cell(actions)="row">

        <div class="d-flex justify-content-end ">
          <b-button variant="flat-info" title="عرض المزيد من المعلومات" size="sm" @click="row.toggleDetails">
            المزيد
            <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
          <actions-table @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)" />
        </div>
      </template>
      <template #row-details="row">
      <b-card>
        <div v-html="row.item.content" >

        </div>
      </b-card>
      </template>
    </data-table>
    <form-modal ref="estateModalAboutContent" :formSchema="estatesFormSchema" title="إضافة" v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
    </form-modal>
  </b-card>
</template>

<script>
import { BCard, BButton, BCollapse } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/data-table/index.vue"
import FormModal from "@/components/FormModal.vue"
import HeaderTable from "@/components/data-table/components/header-table.vue"
import ActionsTable from "@/components/data-table/components/actions-table.vue"
import dataSchema from "./mixins"
export default {
  components: { BCard, BButton, BCollapse, DataTable, FormModal, HeaderTable, ActionsTable },
  data() {
    return {
      activModel: false,
      fields: [
        { key: "title", label: "العنوان" },
        //{ key: "content", label: "المحتوى" },
        { key: "actions", label: "" }
      ],

      estatesFormSchema: {
        title: {
          component: "FormInput",
          key: "title",
          attrs: {
            id: "first_name",
            label: "العنوان",
            rules: "required",
          },
        },
        content: {
          component: "FormQuill",
          key: "content",
          attrs: {
            label: "المحتوى",
            rules: "required",
          },
        },
      }
    };
  },
  mixins: [dataSchema],
  methods: {
    ...mapActions("manageCenter/legalAwareness", ["update", "delete", "create"]),
    onAdd() {
      this.$refs.estateModalAboutContent.init({});
      this.activModel = true;
    },
    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف", {
          title: "تأكيد حذف",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.delete({ id: item.id }).then(() => {
              this.$refs.estatesTableee.refreshTable();
            });
        });
    },
    onUpdateClicked(form) {
      this.$refs.estateModalAboutContent.init(form);
      this.activModel = true;
    },
    onModalConfirmed(form) {
      if (form.id) {
        this.update({ data: form, id: form.id }).then(() => {
          this.$refs.estatesTableee.refreshTable();
          this.activModel = false;
        });
      } else
        this.create({ data: form }).then(() => {
          this.$refs.estatesTableee.refreshTable();
          this.activModel = false;
        });
    },

  },

  computed: {
    ...mapGetters("manageCenter/legalAwareness", ["loading"]),
  },
};
</script>